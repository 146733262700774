<template>
	<div>
		<div>
			<e-select
				track-by="id"
				label="label"
				v-model="searchValue"
				group-label="label"
				group-values="values"
				:group-select="false"
				:options="options"
				:placeholder="$t('global.rechercher')"
				:loading="isLoading"
				:sortable="false"
				:internal-search="false"
				preserve-search
				reset-after
				@search-change="onSearch"
				@select="searchHorse"
				ref="input"
			>
				<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>
	</div>
</template>

<script>
import _debounce from 'lodash/debounce'
import Tiers from '@/mixins/Tiers.js'

export default {
	mixins: [Tiers],
	props:['town', 'tiers_postalcode', 'tiers_town'],
    data() {
        return {
			options: [
				{
					values: []
				},
				{
					values: []
				},
			],
			searchValue: null,
			isLoading: false,
			debouncedSearch: _debounce(this.search, 300)
		}
	},
	mounted() {
		this.init_component()
	},
	methods: {
		init_component() {
			this.$refs.input.$el.focus()
		},
		onSearch(search_val) {
			this.debouncedSearch(search_val)
		},
		async search(search_val) {
			if(!search_val) {
				return
			}

			this.isLoading = true

			this.options[0].values = [{
				id: 1,
				ifce: 0,
				libelle: search_val,
				postalcode: search_val,
				label: search_val
			}]

			const communes = this.getCityAndCp(search_val, this.town)
				.then(res => {
					this.options[1].values = res.map(commune => {
						return {
							id: commune.communedps_id,
							ifce: 1,
							libelle: commune.communedps_libelle,
							postalcode: commune.communedps_postalcode,
							label: commune.communedps_postalcode + ' - ' + commune.communedps_libelle
						}
					})
				})

			// Ajout des résultats au e-select
			await Promise.all([communes])

			this.isLoading = false
		},
		async searchHorse(option) {
			if(this.town && !option.ifce) {
				this.internalTiersTown = option.libelle
			}
			else if(!this.town && !option.ifce) {
				this.internalTiersPostalcode = option.postalcode
			}
			else {
				this.internalTiersTown = option.libelle
				this.internalTiersPostalcode = option.postalcode
			}
		}
	},
	computed: {
		internalTiersTown: {
			get() {
				return this.tiers_town
			},
			set(val) {
				this.$emit('update:tiers_town', val)
			}
		},
		internalTiersPostalcode: {
			get() {
				return this.tiers_postalcode
			},
			set(val) {
				this.$emit('update:tiers_postalcode', val)
			}
		}
	}
}
</script>
